<template>
  <div>
    <div class="page-title dashboard">
      <div class="container">
        <div class="row pb-3">
          <div class="col-12 col-sm-6 my-2">
            <div class="page-title-content">
              <p>
                Welcome Back,
                <span> Jay Smith</span>
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-2">
            <ul class="text-right breadcrumbs list-unstyle">
              <li>
                <router-link to="settings">Settings </router-link>
              </li>
              <li class="active"><a href="#">Security</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="card sub-menu">
              <div class="card-body">
                <ul class="d-flex">
                  <li
                    v-for="link in links"
                    :key="link.icon"
                    class="nav-item"
                    :class="$route.name === link.title ? 'active' : null"
                  >
                    <router-link :to="link.to" class="nav-link">
                      <i :class="link.icon"></i>
                      <span>{{ link.text }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Security</h4>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-xl-4">
                    <div class="id_card">
                      <img src="@/assets/images/id.png" alt="" class="img-fluid" />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="id_info">
                      <h4>Jay Smith</h4>
                      <p class="mb-1 mt-3">ID: 0024 5687 2254 3698</p>
                      <p class="mb-1">
                        Status: <span class="font-weight-bold">Verified</span>
                      </p>
                      <router-link to="verify-step-2" class="btn btn-success mt-3">
                        New ID
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="phone_verified flex-wrap my-2">
                      <h5>
                        <span><i class="fa fa-envelope"></i></span>
                        otc@getcoins.com
                      </h5>
                      <div class="verify p-2">
                        <div class="verified">
                          <span><i class="la la-check"></i></span>
                          <a href="#">Verified</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="phone_verified flex-wrap my-2">
                      <h5>
                        <span><i class="fa fa-phone"></i></span> +1 234 567 8901
                      </h5>
                      <div class="verify p-2">
                        <div class="verified">
                          <span><i class="la la-check"></i></span>
                          <a href="#">Verified</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    darkMode: false,
    links: [
      {
        to: 'profile',
        title: 'Profile',
        icon: 'mdi mdi-bullseye',
        text: 'Account Overview',
      },
      {
        to: 'settings-security',
        title: 'SettingsSecurity',
        icon: 'mdi mdi-lock',
        text: 'Security Details',
      },
      {
        to: 'settings-account',
        title: 'SettingsAccount',
        icon: 'mdi mdi-bank',
        text: 'Linked Bank Accounts',
      },
      {
        to: 'settings-crypto',
        title: 'SettingsCrypto',
        icon: 'mdi mdi-bank',
        text: 'Linked Crypto Wallets',
      },
      {
        to: 'settings',
        title: 'VipApplication',
        icon: 'mdi mdi-lock',
        text: 'VIP Application',
      },
    ],
  }),
  watch: {
    darkMode(val) {
      if (val === true) {
        document.documentElement.className = null;
        localStorage.setItem('gc-theme', 'dark');
      } else {
        document.documentElement.className = 'theme--light';
        localStorage.setItem('gc-theme', 'light');
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem('gc-theme');
    this.darkMode = theme === 'dark';
  },
};
</script>

<style lang="scss" scoped>
.card-profile__info h5 {
  display: inline-block;
}

.sub-menu {
  ul {
    @include screen('phone-land') {
      display: inline-block !important;
    }

    li {
      a {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        color: var(--body-color);

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--primary);
          background-color: transparent;
        }
      }

      &.active {
        a {
          color: var(--primary);
        }
      }

      i {
        font-weight: bold;
        margin-right: 7px;
        font-size: 20px;
      }

      span {
        font-weight: 400;
      }
    }
  }
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-color);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;

  h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: var(--body-color);

    span {
      // background: var(--primary);
      color: var(--primary);
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid var(--border-color);
      height: 40px;
      width: 40px;
      display: inline-block;
    }
  }

  .verified,
  .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    span {
      background: var(--primary);
      color: var(--white);
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .not-verify {
    span {
      background: var(--danger);
    }
  }
}

// Security
.id_card {
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 20px 0px;
}

table {
  color: var(--text);
}

.dashboard {
  margin-left: 0;
}
</style>
